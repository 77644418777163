.project-container {
  margin-top: 100px;
  margin-left: 11.5%;
  width: 77%;
  height: 432px;
  margin-bottom: 20px;
  padding: 0px;
}

.project-head {
  margin: 0px 0px 48px 0px;
  display: flex;
  flex-direction: row;
  height: 64px;
}

.project {
  width: 90px;
  height: 38px;
  border: 1px solid #f2f2f2;
  padding: 8px;
  margin-top: 13px;
  align-items: center;
  justify-self: center;
}

.projects {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  line-height: 21.78px;
  color: #f2f2f2;
  align-items: center;
  justify-content: center;
}

.arrow-buttons {
  display: flex;
  flex-direction: row;
  padding: 0px;
  width: 144px;
  position: absolute;
  right: 12%;
}

.arrow-left,
.arrow-right {
  background-color: transparent;
  border: none;
  border-radius: 0%;
  width: 64px;
  height: 64px;
  padding: 0px;
}

.arrow-left {
  margin-right: 16px;
}

.arrow-left svg,
.arrow-right svg {
  width: 100%;
  height: 100%;
  fill: #f2f2f2;
}

.project-body {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  height: 320px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: hidden;
}

.project-slider {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.project-card {
  flex: 0 0 calc(50% - 20px);
  height: 320px;
  border: 1px solid #f2f2f2;
  padding: 16px;
  margin-right: 40px;
}

.project-title-box {
  width: 100%;
  height: 61px;
  margin-bottom: 8px;
}

.project-title {
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 48px;
  line-height: 61.25px;
  color: #f2f2f2;
  text-align: left;
}

.project-image-box {
  margin-top: 8px;
  width: 100%;
  height: 219px;
}

.project-img {
  width: 100%;
  height: 219px;
  margin-right: 0;
  border-radius: 0px;
}

@media (max-width: 1190px) {
  .project-title {
    font-size: 40px;
  }
}

@media (max-width: 1020px) {
  .project-title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .project-container {
    margin-top: 60px;
    margin-left: 6%;
    width: 88%;
    height: 432px;
  }

  .project-head {
    margin-bottom: 23px;
  }

  .projects {
    font-weight: 400;
  }

  .arrow-buttons {
    right: 6%;
  }

  .project-body {
    width: 112%;
    height: 345px;
  }

  .project-card {
    flex: 0 0 calc(100% - 40px); /* occupy the full width minus margin */
    margin-right: 40px;
  }
}
