.member-box {
  width: 228px;
  height: 228px;
  position: relative;
  overflow: hidden;
  margin-right: 24px;
  margin-bottom: 32px;
}

.member-img {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
}

.member-box:hover .member-img {
  opacity: 0.5;
}

.hover-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  padding: 10px 15px;
  opacity: 0;
  transition: opacity 0.3s;
}

.member-box:hover .hover-info {
  opacity: 1;
}

.member-name {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  color: #f2f2f2;
  margin-bottom: 8px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.logo:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .member-box {
    width: 43%;
    height: 157.885px;
    object-fit: cover;
  }
}
