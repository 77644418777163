.Home-page-content {
  position: relative;
  top: 55px;
  margin-left: 11.5%;
  width: 77%;
  padding: 0px;
  height: 584px;
  margin-bottom: 75px;
}
.motto {
  display: none;
}
.Home-image {
  width: 100%;
  height: 584px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .top-bar {
    height: 10px;
    width: 100%;
    margin-bottom: 32px;
    background-color: #000000;
  }
}
@media (max-width: 768px) {
  .motto {
    display: block;
    color: #f2f2f2;
    font-family: "Space Grotesk";
    font-weight: 700;
    font-size: 64px;
    line-height: 81.66px;
  }
  .Home-page-content {
    top: 100px;
    margin-left: 6%;
    width: 88%;
  }
  .Home-image {
    position: absolute;
    bottom: 40px;
    height: 400px;
  }
}
