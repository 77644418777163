.navbar-fixed {
  width: 100%;
  position: fixed;
  z-index: 1000;
}

.active-link {
  background-color: rgb(97, 91, 91);
  color: #000;
}

.navbar {
  height: 64px;
  background-color: transparent;
  padding-left: 11.5%;
  padding-right: 11.5%;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 56px;
  background: rgba(0, 0, 0, 0.5);
}
.container-fluid {
  padding: 0px;
  height: 100%;
}
.navbar-brand,
.nav-link {
  color: #f2f2f2;
  font-family: "Space Grotesk";
  font-weight: 400;
  font-size: 18px;
  line-height: 64px;
  border: 1px solid #f2f2f2;
  padding: 0px;
  text-align: center;
  height: 100%;
}
.navbar-brand {
  width: 26.8%;
  margin-right: 0px;
  padding: 0px;
}
.collapse {
  width: 50.2%;
  height: 100%;
}
.navbar-nav {
  width: 100%;
  height: 100%;
}
.nav-link {
  text-align: center;
  color:#f2f2f2;
  width: 20%;
}
.nav-link:focus {
  color: #f2f2f2;
}
.nav-link:hover {
  cursor: pointer;
  color: #f2f2f2;
}
.navbar-brand:hover {
  cursor: none;
  color: #f2f2f2;
}
@media (min-width: 768px) {
  .top-bar {
    height: 10px;
    width: 100%;
    margin-bottom: 32px;
    background-color: #000000;
  }
}
@media (max-width: 768px) {
  /* .devcom-svg-container {
    margin-top: auto;
    width: 100%;
    text-align: center;
    align-self: flex-end;
  } */
  .collapse {
    width: 100%;
    position: absolute;
    top: 96px;
    left: 0;
    right: 0;
    z-index: 1001;
  }

  .navbar-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 200px;
  }
  .navbar-fixed {
    position: absolute;
    z-index: 10;
  }
  .navbar {
    width: 100%;
    height: 96px;
    padding: 0px;
    margin-bottom: 16px;
  }
  .navbar-brand {
    width: 75%;
    line-height: 22px;
    text-align: left;
  }
  .title {
    position: relative;
    left: 23px;
    top: 36.4px;
  }
  .navbar-toggler {
    width: 25%;
    height: 100%;
    padding: 0px;
    border-radius: 0px;
    border: 1px solid #f2f2f2;
  }
  .cross-icon {
    width: 50%;
    height: 50%;
    color: #ffffff;
    font-size: 24px;
  }
  .navbar-toggler-icon {
    width: 50%;
    height: 50%;
  }
  .nav-link {
    width: 100%;
    border: 0px;
    font-size: 21px;
    background-color: rgba(0, 0, 0, 0.6);
    margin-bottom: 5px;
    margin-top: 15px;
  }
}
