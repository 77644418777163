.outer-team-container {
  width: 77%;
  margin-left: 11.5%;
  padding: 0px;
  margin-top: 10.5%;
}
.head-section {
  width: 100%;
  margin-bottom: 24px;
}
.heading {
  width: 100%;
  height: 64px;
  margin-bottom: 16px;
}
.headings {
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  color: #f2f2f2;
}
.content {
  width: 100%;
  height: 44px;
}
.contents {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 21.78px;
  color: #afb9cc;
}
.body-section {
  width: 110%;
}
.OC-section,
.design-lead-section,
.project-lead-section,
.Developers-section,
.designers-section {
  width: 100%;
  margin: 0px;
  margin-bottom: 16px;
}
.position {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.post {
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 40px;
  line-height: 64px;
  color: #f2f2f2;
  text-align: left;
}
.post-holders {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.plus_icon {
  display: none;
}
.row {
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  p {
    margin-bottom: 0rem;
  }
  .body-section {
    width: 100%;
  }
  .outer-team-container {
    margin-left: 6%;
    width: 88%;
    padding-top: 34.5%;
  }
  .headings {
    line-height: 72px;
  }
  .content {
    height: fit-content;
  }
  .position {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .post {
    align-items: center;
    font-size: 24px;
    /* line-height: 30.62px; */
  }
  .post-holders {
    margin-top: 8px;
    margin-left: 12px;
    /* margin-right: 24px; */
    width: 100%;
    display: flex;
    justify-content: space-between; /* Distributes space evenly between child elements */
  }
  .plus_icon {
    display: block;
    align-self: center;
    height: 24px;
    z-index: 1;
  }
}

@media (max-width: 866px){
  .post-holders{
    justify-content: center;
  }
}

@media (max-width: 330px) {
  p.headings{
    font-size: 52px;
  }
}