.About-us-container {
  margin-left: 11.5%;
  width: 77%;
  padding: 0px;
}
/*adjust the box sizes to adjust the content inside it.*/
.About-us-content {
  width: 100%;
  margin: 0px;
  display: inline-block;
}
.text-column,
.image-column {
  height: 560px;
  padding: 0px;
  position: relative;
}
.text-column {
  width: 48.7%;
  float: left;
}
.image-column {
  width: 48.7%;
  float: right;
}
.About-us,
.About-work {
  width: 100%;
  height: 46%;
  margin: 0px;
}
.About-us {
  margin-bottom: 8%;
}
.About-us-title,
.About-work-title {
  padding: 0px;
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 64px;
  color: #f2f2f2;
  line-height: 64px;
  margin-bottom: 6.25%;
}
/*font size can't be reduced by percentage use media method to reduce it or search.*/
.About-us-desc,
.About-work-desc {
  padding: 0px;
  color: #afb9cc;
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 21.78px;
  margin-bottom: 8.59%;
}
.clrpicker_image,
.terminal_image {
  display: none;
}
.devcom-img {
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 1000px) {
  .About-us-title,
  .About-work-title {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (max-width: 768px) {
  .About-us-container {
    margin-left: 6%;
    margin-right: 6%;
    width: 88%;
    height: fit-content;
  }
  .About-us-content {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .text-column,
  .image-column {
    width: 100%;
    height: fit-content;
  }
  .About-us,
  .About-work {
    height: fit-content;
  }
  .About-us-title,
  .About-work-title {
    line-height: 72px;
  }
  .About-us-desc,
  .About-work-desc {
    margin-bottom: 32px;
  }
  .devcom-img {
    display: none;
  }
  .clrpicker_image {
    display: block;
    padding: 0px;
    margin-left: 5.25%;
    width: 89.5%;
  }
  .terminal_image {
    display: block;
    padding: 0px;
    width: 100%;
  }
}

:root {
  --min-margin: 40px;
  --max-margin: 170px;
  --min-width: 768px;
  --max-width: 1100px;
}

@media only screen and (min-width: 768px) and (orientation: landscape) and (max-width: 1050px) {
  .About-us{
    height: max-content;
    margin-bottom: 15px;
  }
  .About-work{
    /* margin-top:10px; */
    height:max-content;
  }
  .About-us-container{
    margin-bottom: min(33vw,256px);
    height: max-content;
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) and (max-width: 1150px){
  .About-us{
    height: max-content;
    margin-bottom: 15px;
  }
  .About-work{
    /* margin-top:10px; */
    height:max-content;
  }
}
